import { navigate } from 'gatsby'
import React from 'react'
import Layout from 'components/Layout'
import Loader from 'components/Loader'
import View from 'components/View'
import paths from 'constants/paths'
import useEventPageVisited from 'hooks/use-event-page-visited'
import * as cookie from 'libs/cookie'
import url from 'libs/url'

export default function Reset() {
  useEventPageVisited('Reset')

  React.useEffect(() => {
    const { cookie: cookieKey } = url.getQueryString()

    cookie.deleteCookie(cookieKey)

    navigate(paths.HOME)
  }, [])

  return (
    <Layout className="Reset">
      <View flush>
        <Loader overlay={false} />
      </View>
    </Layout>
  )
}
